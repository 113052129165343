(function($) {
    $(document).ready(function($) {
        /* $('body').on('added_to_cart',function() {
            alert("testing!");
        }); */
    });
    /* $('body').mouseleave( function(){
        MicroModal.init();
        MicroModal.show('cart-modal');
        //alert("test");
        // const myModalEl = jQuery('.modal');
        // myModalEl.show;
    }) */
})(jQuery); // Fully reference jQuery after this point.